@import 'src/scss/mixins';

.content__container {
  padding: 0 24px 60px;

  @include for-tablet {
    margin-left: 36px;
  }
  @include for-mobile {
    margin-left: 15px;
  }
}

.navigation__back {
  padding-top: 20px;
}

.id {
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

.vacancy__publication__date {
  font-size: 1rem;
  font-weight: 500;
}

.team__label {
  margin-bottom: 0px;
  color: #a7a7a7;
}

.team__name {
  color: #199b3e;
  font-weight: 500;
}

.field {
  color: #a7a7a7;
}

.field__value {
  font-weight: 500;
}

.comment__label {
  color: #78858b;
}

.comment__value {
  font-weight: 400;
  line-height: 140%;
}

.close__button {
  margin-bottom: 1.5rem;
}

.vacancy__responses__title {
  font-size: 26px;
  font-weight: 700;
  line-height: 140%;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.icon__button {
  margin: 0.5rem;
}

.star {
  margin-left: 0.5rem;
}

.table__container {
  margin-right: -1.5rem;

  @include for-mobile {
    margin-right: 17px;
  }
}

.vanancy__container {
  box-shadow: none;
  border: 1px solid var(--border-bottom-light);
  padding: 32px 32px 48px;
}

.loadingBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}
