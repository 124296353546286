.header {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(120, 133, 139, 0.3);
  min-height: 65px;
  overflow: auto;
  margin-right: 1.5rem;

  &::-webkit-scrollbar {
    width: 10px;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 10px;
    border: 3px solid red;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;

    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    align-items: center;

    li {
      a {
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 27px 0 12px 0;

        text-decoration: none;
        color: #78858b;
        margin: 0 24px 0 0;
        border-bottom: 1px solid rgba(64, 224, 208, 0);
        transition: color 0.3s ease, border-bottom 0.3s ease;

        svg {
          path {
            fill: #78858b;
            transition: fill 0.4s ease;
          }
        }
      }

      :global a.active {
        color: var(--text-default-color);
        background: transparent;
        border-radius: 0;
        border-bottom: 1px solid var(--text-default-color);

        svg path {
          fill: var(--text-default-color);
        }
      }

      &:hover {
        a {
          color: var(--text-default-color);
          border-bottom: 1px solid var(--text-default-color);

          svg path {
            fill: var(--text-default-color);
          }
        }
      }
    }
  }
}
.headerBack {
  padding-left: 25px;
  padding-top: 25px;
}
.tabs {
  display: flex;
  padding: 0 0 12px 0;
  border-bottom: 1px solid #dcdcdc;
  margin-right: 32px;
  color: #78858b;
  font-size: 14px;
  font-weight: 500;

  button {
    background: none;
    margin-right: 24px;
    border: none;
    border-bottom: 1px solid rgba(64, 224, 208, 0);
  }
}
