.container {
  padding: 1.5rem 2rem 2rem 2rem;
  grid-column: span 2;
  margin-bottom: 3rem;

  @media (max-width: 1470px) {
    grid-column: auto;
  }
}

.title {
  margin-bottom: 1.5rem;
}

.select__label {
  color: #78858b;
}

.select {
  width: 320px !important;
  margin-bottom: 2rem !important;
}

.content {
  display: flex;
  justify-content: space-between;
}

.select {
  width: 334px;
  background-color: #fff;
  &_placeholder {
    color: var(--count-dark);
    font-size: 14px;
    line-height: 23px;
    margin: 0;
  }

  &_selectedProjects {
    margin: 0;
    font-size: 14px;
    line-height: 23px;
    color: var(--count-light);
  }
  &_clearIcon {
    width: 11px;
    height: 11px;
    cursor: pointer;
  }
  &_selectedCounter {
    border-radius: 50%;
    background-color: #007236;
    padding: 0 7px;
    color: #fff;
    font-size: 14px;
    margin-right: 4px;
  }
  &_option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6px;
  }
}
