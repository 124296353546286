:root {
  --bg-light: #fff;
  --bg-dark: #1e1f25;
  --bg-black: #131517;
  --bg-default: #fafafa;
  --darkAcitveBtn-dark: #131517;
  --lightActiveBtn-light: #f8f8fb;
  --bg-hover-circleSwitcher: #199b3e;
  --text-hover-color: #199b3e;
  --text-default-color: #007236;
  --light-green: #10c943;
  --text-color-dark-grey: #26282c;
  --text-light-green: #21b84b;
  --border-bottom-light: #f0f0f0;
  --border-light-gray: #e4e4e4;
  --border-light-white: #f5f5f5;
  --border-blue: #189ced;
  --border-bottom-dark: #39393a;
  --border-gray-line: #f5f7fa;
  --buttonLightTheme: #dcdcdc;
  --buttonDarkTheme: none;
  --itemSidebarColor-light: #78858b;
  --itemSidebarColor-dark: #a7a7a7;
  --color-gray: #515151;
  --transition: transform 0.2s ease-in-out;
  --count-light: #39393a;
  --count-dark: #a7a7a7;
  --bg-green: #199b3e;
  --bg-light-gray: #f2f2f2;
  --bg-light-pink: #ffeaea;
  --bg-light-grayish: #f8f8f8;
  --bg-pink: #ffc2c2;
  --color-red: #e04040;
  --color-light-gray: #717171;
  --hover-lite: #f3faf5;
  --cancel-color: #78858b;
  --gray-color: #a6a6a6;
  --bg-gray: #c3c3c3;
  --border-gray: #dcdcdc;
  --charts-green: #34d15f;
  --charts-red: #fe5959;
  --yellow-light: #eeba00;
  --g-color-base-float: rgb(255, 255, 255);
  --g-text-body-1-font-size: 13px;
  --_--border-color: rgb(229, 229, 229);
  --g-color-line-generic-solid: rgb(229, 229, 229);
  --g-color-sfx-shadow: rgba(0, 0, 0, 0.15);
  --g-color-base-simple-hover: rgba(0, 0, 0, 0.05);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px rgba(255, 255, 255, 1);
  border-radius: 4px;
}
.chatForm {
  width: 100%;
  fieldset {
    border: 0;
  }
  p {
    display: none;
  }
}

a {
  color: var(--text-default-color);

  &:hover {
    color: var(--text-default-color);
  }
}
