.buttons {
  margin-top: 56px;
  display: flex;
  justify-content: flex-end;

  &__cancel {
    margin-right: 0.5rem !important;
    color: var(--cancel-color) !important;

    &:hover {
      color: white !important;
    }
  }
}
