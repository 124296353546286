.header {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  border-bottom: #dcdcdc 1px solid;
  a {
    display: inline-block;
    color: #78858b;
    padding-bottom: 12px;
    &:hover {
      color: black;
    }
    font-weight: 500;
  }
  :global .selected {
    color: #199b3e;
    border-bottom: #21b84b 1px solid;
    margin-bottom: -1px;
    &:hover {
      color: #21b84b;
    }
  }
}
