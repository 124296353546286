.about__me__info {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
  margin-top: 2rem;
}

.about__me__info__title {
  font-weight: 700;
  margin-bottom: 1.125rem;
}

.about__me__info__content {
  font-weight: 500;
}

.resume {
  color: var(--bg-green);
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  max-width: max-content;
}

.certificate {
  color: var(--bg-green);
  display: flex;
  flex-direction: row;
  gap: 0.375rem;
  max-width: max-content;
}
