.empty__projects {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;

  &__content {
    color: var(--cancel-color);
    font-weight: 500;
  }
}
