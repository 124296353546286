@import 'src/scss/mixins';

.main {
  padding: 0 72px;
  background: var(--bg-light-grayish);
  @include for-tablet {
    padding: 0 36px;
  }
  @include for-mobile {
    padding: 0 15px;
  }
}

.container {
  max-width: 1440px;
  margin: 0 auto;
}

.landing {
  h3 {
    font-weight: 500;
    font-size: 38px;
    line-height: 130%;
  }
  h4 {
    font-weight: 500;
    font-size: 24px;
    line-height: 130%;
  }

  p {
    font-size: 18px;
    margin-bottom: 0;
  }
}

.green__text {
  color: var(--text-default-color) !important;
}
.white__text {
  color: var(--bg-light);
}
.bigger__text {
  font-size: 22px !important;
}

.landing__list {
  p {
    margin-bottom: 1rem !important;
    display: flex;
    &:before {
      flex-shrink: 0;
      display: block;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 3px;
      background: rgb(25, 155, 62);
      margin-right: 0.625rem;
      margin-top: 7px;
    }
  }
}

.banner {
  background-color: #030c22;
  min-height: 640px;
  display: flex;
  align-items: stretch;
  padding-left: 72px;
  padding-right: 72px;

  @include for-tablet {
    padding: 0;
  }
  &__content {
    display: flex;
    align-items: center;
    min-height: 100%;
    width: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0) 82%, rgba(2, 12, 34, 0.9) 91%),
      url('../../../src/assets/img/landing/landingBanner.png');
    &__wrapper {
      max-width: 730px;
    }
  }
}

.components {
  padding-top: 84px;
  padding-bottom: 120px;
  &__card {
    box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
    background: var(--white);
    padding: 2rem;
    border-radius: 12px;
    & h4 {
      margin-bottom: 46px;
    }
    &__list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row-gap: 40px;
    }
  }
}

.image__block {
  justify-content: space-between;
  padding-bottom: 100px;
  &__textblock {
    max-width: 560px;
  }
  &__title {
    font-weight: 500;
    font-size: 38px;
    line-height: 45px;
    margin-bottom: 45px;
  }
  &__yourProjects {
    & img {
      box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
    }
  }
  &__card {
    box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
    background-color: var(--bg-light);
    border-radius: 12px;
    padding: 24px 32px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & h4 {
      margin-bottom: 1.25rem;
    }
    & img {
      display: block;
      width: 100%;
      height: auto;
    }
    &:first-child {
      margin-right: 1.5rem;
    }
  }
  &__desktop {
    display: block;
  }
  &__mobile {
    display: none;
  }
}

.students {
  padding-bottom: 140px;
  &__card {
    box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
    background: var(--white);
    padding: 2rem;
    border-radius: 12px;
  }
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    &__desktop {
      display: block;
    }
    &__mobile {
      display: none;
    }
    &__item {
      padding-top: 24px;
      &:not(:nth-last-child(-n + 2)) {
        border-bottom: 1px solid #dcdcdc;
      }
    }
  }
}

.yandex_instruments {
  padding-bottom: 110px;
  & h2 {
    margin-bottom: 4rem;
  }
  &__item {
    flex-basis: 33%;
    margin-bottom: 32px;
    & img {
      display: block;
      flex-shrink: 0;
      margin-bottom: 29px;
    }
  }
  &__list {
    & p {
      margin-bottom: 1rem;
    }
  }
}

.actual__work {
  .image__block__textblock {
    max-width: unset;
  }
  & img {
    box-shadow: 5px 5px 30px 0 rgba(111, 111, 111, 0.16);
  }
}

.enter {
  padding-bottom: 120px;
  &__wrapper {
    border-radius: 12px;
    padding: 40px;
    box-shadow: 5px 5px 40px 0 rgba(0, 196, 79, 0.12);
    background: url('../../assets/img/landing/enter_background.png');
    & h2 {
      text-align: center;
      margin-bottom: 54px;
    }
    & button {
      font-size: 18px;
      min-width: 465px;
      padding: 22px;
      &:hover {
        background-color: rgb(17, 108, 43);
      }
    }
  }
}

@media (max-width: 1024px) {
  .landing {
    & h3 {
      font-size: 32px;
    }
  }

  .banner {
    min-height: 440px;
    &__content {
      background: url(../../../src/assets/img/landing/landingBanner_tablet.png);
      padding: 36px;
      background-size: 100% 100%;
    }
  }

  .yandex_instruments {
    padding-bottom: 72px;
    & h2 {
      margin-bottom: 40px;
    }
    &__wrapper {
      flex-wrap: wrap;
    }
    &__item {
      flex-basis: 100%;
      display: flex;
      margin-bottom: 56px;
      & img {
        margin-right: 1.5rem;
        width: 336px;
        height: 233px;
      }
    }
  }

  .components {
    padding-top: 64px;
    padding-bottom: 72px;
    &__card {
      &__list {
        grid-template-columns: 1fr;
        grid-row-gap: 36px;
        & svg {
          max-width: 56px;
          max-height: 56px;
        }
      }
    }
  }

  .image__block {
    flex-direction: column;
    &__textblock {
      max-width: unset;
      margin-bottom: 2rem;
    }
    &__specialists {
      flex-wrap: wrap;
      & .image__block__card {
        &:first-child {
          margin-right: 0;
          margin-bottom: 2rem;
        }
      }
    }
    &__diploma {
      padding-bottom: 72px;
    }
    & img {
      width: 100%;
    }
    &__desktop {
      display: none;
    }
    &__mobile {
      display: block;
    }
  }

  .students {
    padding-bottom: 72px;
    &__list {
      grid-template-columns: 1fr;
      &__item {
        flex-wrap: wrap;
        &:not(:nth-last-child(-n + 2)) {
          border-bottom: none;
        }
        &:nth-child(2n) {
          padding-top: 1.5rem;
          border-bottom: 1px solid #dcdcdc;
        }
        &:last-child {
          border-bottom: none;
        }
      }
      &__desktop {
        display: none;
      }
      &__mobile {
        display: block;
        width: 100%;
      }
    }
  }

  .enter {
    padding-bottom: 150px;
    &__wrapper {
      padding: 32px;
      & h2 {
        margin-bottom: 46px;
      }
      & button {
        font-size: 18px;
        min-width: 465px;
        padding: 22px;
      }
    }
  }
}

@media (max-width: 577px) {
  .landing {
    & h3 {
      font-size: 23px;
      line-height: 125%;
    }
    & h2 {
      font-size: 26px;
      line-height: 115%;
    }
    & h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 130%;
    }
    & section {
      padding-bottom: 3rem;
    }
    & .bigger__text {
      font-size: 20px !important;
    }
  }

  .banner {
    &__content {
      background: url(../../../src/assets/img/landing/landingBanner_mobile.png);
      padding: 15px;
      background-size: 100% 100%;
    }
  }

  .components {
    padding-top: 40px;
    padding-bottom: 48px;
    &__card {
      padding: 1rem;
      & h4 {
        margin-bottom: 20px;
      }
      & svg {
        width: 40px;
        height: 40px;
      }
      &__list {
        grid-row-gap: 20px;
      }
    }
  }

  .image__block {
    &__card {
      padding: 1.5rem 1rem;
      & h4 {
        font-size: 20px;
        line-height: 130%;
        margin-bottom: 1rem;
      }
    }
  }

  .students {
    &__card {
      padding: 1.5rem 1rem;
    }
    &__list {
      &__item {
        &:nth-child(2n) {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0 !important;
        }
      }
    }
  }

  .yandex_instruments {
    & h2 {
      margin-bottom: 1.5rem;
    }
    &__item {
      flex-direction: column;
      margin-bottom: 40px;
      & img {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 1.5rem;
      }
    }
  }

  .enter {
    padding-bottom: 80px;
    &__wrapper {
      padding: 15px;
      & h2 {
        font-size: 20px;
        margin-bottom: 24px;
      }
      & button {
        font-size: 16px;
        min-width: 100%;
        padding: 14px;
      }
    }
  }
}

.image {
  max-width: 730px;
  @include for-tablet {
    width: 100%;
  }
  @include for-mobile {
    width: 100%;
  }
}
