.modal__wrapper {
  max-width: 760px !important;
}

.modal {
  margin-top: 1rem;
  font-size: 14px;

  &__total__value {
    font-weight: 500;
  }

  &__table {
    margin-top: 1.25rem;

    &__head {
      display: grid;
      grid-template-columns: 111px 75px 390px 1fr;
      grid-column-gap: 28px;

      &__item {
        color: var(--cancel-color);
        font-size: 12px;
        margin-bottom: 0.75rem;
      }
    }
  }
}

.buttons {
  margin-top: 56px;
  display: flex;
  justify-content: flex-end;

  &__cancel {
    margin-right: 0.5rem !important;
    color: var(--cancel-color) !important;

    &:hover {
      color: white !important;
    }
  }

  &__add {
  }
}
