@import 'src/scss/mixins';

.box {
  border: 1px solid var(--border-light-gray);
  border-radius: 4px;
  padding: 18px 0 32px 24px;
  width: 100%;
  height: fit-content;
  margin: 16px 16px;
  @include for-tablet {
    margin: 0;
  }
}

.status {
  border-radius: 21px;
  border: 1px solid var(--color-red);
  padding: 5px 10px;
  gap: 10px;
  color: var(--color-red);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--count-light);
}

.description {
  color: var(--count-dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.gap {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
