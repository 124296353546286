.about__company__info {
  margin-right: 2.5rem;
  margin-left: 2.5rem;
}

.about__company__info__title {
  font-weight: 700;
  margin-bottom: 1.5rem;
}

.about__company__info__logo {
  display: flex;
  margin-bottom: 1.25rem;
}

.about__company__main__info {
  display: flex;
  flex-direction: column;
  margin-left: 1.125rem;
}

.about__company__email {
  font-weight: 500;
}

.about__company__phone {
  font-weight: 500;
}

.about__company__url {
  margin-top: 0.75rem;
  margin-bottom: 0.25rem;
}
