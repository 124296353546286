.main__info {
  display: flex;
  margin-left: 2.5rem;
  margin-top: 2rem;
}

.main__info__content {
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  width: 100%;
}

.main__info__top {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.main__info__name {
  margin-right: 0.375rem;
}

.edit__button {
  margin-right: 2.5rem !important;
  height: 38px !important;
}

.menuText {
  font-weight: 500;
  line-height: 1.125rem;
}

.menuItem {
  gap: 0.5rem;
  width: 15.5rem;
  padding: 0.75rem 0rem 0.75rem 0.75rem;
  svg {
    path[fill] {
      fill: #199b3e;
    }
    path[stroke] {
      stroke: #199b3e;
    }
  }
}

.main__info__role {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}

.rating__icon {
  margin-left: 0.875rem;
  margin-right: 0.5rem;
}

.main__info__telegram {
  margin-left: 2.25rem;
}

.star {
  margin-left: 0.25rem;
}

.default_icon {
  width: 180px;
  height: 180px;
  background: var(--bg-pink);
  color: var(--bg-light);
  font-size: 82px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
}

.default_icon::before {
  content: '';
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Серый цвет оверлея */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 4px;
}

.default_icon:hover::before {
  opacity: 1;
}

.editIcon {
  position: absolute;
  top: -55px;
  right: 10px;
  cursor: pointer;
  z-index: 4;
  svg {
    path[fill] {
      fill: #ffffff;
    }
    path[stroke] {
      stroke: #ffffff;
    }
  }
}
