.footer {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 2.5rem 3rem 2.5rem;

  .registration {
    margin-bottom: 1rem;
  }
}

.footer__content {
  font-weight: 500;
}

.button__delete {
  padding: 0 !important;
  max-width: 160px;
  &:hover {
    background-color: transparent !important;
    color: var(--bg-green) !important;
  }
}
