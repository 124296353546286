@import './components/main.scss';
@import '~bootstrap/scss/bootstrap';
@import './PaginationCustom.module.scss';
@import 'src/scss/variables';
@import 'src/scss/mixins';
@import 'src/styles/ApplicationThemecContext.module.scss';

.btn {
  &.btn-text {
    background: transparent;

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 16px 130px;
      width: 100%;
    }
    @media (min-width: 768px) and (max-width: 990px) {
      padding: 16px 220px;
      width: 100%;
    }
  }

  &.btn-cyan {
    color: #fff;
    background: #199b3e;
    font-weight: 600;
    display: flex;
    border-radius: 8px;
    border: 0;
    padding: 16px 32px;

    &:hover {
      font-weight: 600;
      background: #21b84b;
      color: #fff;
    }

    &:active {
      background: #199b3e !important;
      color: #fff !important;
    }

    &-sm {
      padding: 10px 20px;
    }

    @media (min-width: 320px) and (max-width: 767px) {
      padding: 16px 130px;
      width: 100%;
    }
    @media (min-width: 768px) and (max-width: 990px) {
      padding: 16px 220px;
      width: 100%;
    }
  }

  &.btn_back {
    @extend .btn-cyan;
    padding: 10px 15px 10px 10px;
    background: white;
    margin-bottom: 24px;
    color: #78858b;
    font-size: 14px;
    line-height: 19px;
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 120px;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      width: 95px;
      height: 40px;
      padding: 6px 2px;
      margin-left: -8px;
    }

    border: 1px #dcdcdc solid;

    &:hover,
    &:active {
      background: #efefef !important;
      color: #78858b !important;
    }
  }

  &.btn_save {
    @extend .btn-cyan;
    padding: 16px 48px;
    @media (min-width: 768px) and (max-width: 1024px) {
      width: 200px;
    }
    @media (min-width: 320px) and (max-width: 767px) {
      width: 122px;
      padding: 16px 23px;
    }
  }

  &.btn_create {
    @extend .btn-cyan;
    padding: 10px 13px;
  }

  &.btn_editor {
    @extend .color-green;
    padding: 10px 13px;
    font-weight: 500;
    border: 1px solid #199b3e;

    &:hover {
      background: #21b84b;
      color: #fff;

      .pencil {
        stroke: #fff;
      }
    }

    &:active {
      background: #199b3e;
      border: none;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      padding: 0 1px;
      border: 1px solid #199b3e;
      margin-right: 36px;
      span {
        display: none;
      }
      .pencil {
        height: 13.5px;
        width: 13.5px;
      }
    }
    @media (min-width: 320px) and (max-width: 767px) {
      padding: 0 0 0 6px;
      border: 1px solid #199b3e;
      margin-right: 36px;
      span {
        display: none;
      }
      .pencil {
        height: 13.5px;
        width: 13.5px;
      }
    }
  }

  &.btn_logout {
    background: none;
    border: none;
    padding: 0;
    color: #a7a7a7;
    font-weight: 700;
  }

  &.btn-transparent {
    display: block;
    padding: 10px 0;
    border: none;
    color: #78858b;
    font-weight: 500;

    &:hover {
      color: #199b3e;

      path {
        transition: all 0.4s ease;
        fill: #199b3e;
      }
    }
  }

  &.main-btn {
    display: flex;
    background: #199b3e;
    border-radius: 8px;
    padding: 10px 13px;
    border: none;
    color: white;
    white-space: nowrap;
    align-items: center;
    transition: all 0.4s ease;
    justify-content: center;

    > svg {
      margin-right: 0.5rem;
    }

    &:hover {
      transition: all 0.4s ease;
      box-shadow: 0 0 10px rgba(64, 224, 208, 0.29);
    }
  }
}

.wrapper-data {
  display: flex;
  align-items: center;
}

#auth_container {
  @media (min-width: 768px) and (max-width: 1199px) {
    background-image: url('../assets/img/main.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.form-data {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  max-width: 598px;
  min-width: 571px;
  padding: 32px 60px;
  @media (min-width: 768px) and (max-width: 989px) {
    background-color: white;
    border-radius: 12px;
    margin: 35% 1% 100% 0;
    height: 40%;
    width: 80%;
    max-width: 1000px;
    padding: 5%;
  }

  @media (min-width: 990px) and (max-width: 1199px) {
    background-color: white;
    border-radius: 12px;
    margin: 100% 1% 100% 0;
    max-width: 1000px;
    padding: 5%;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    max-width: 380px;
  }
}

.form-container {
  padding-left: 2.5rem;
  padding-bottom: 48px;

  @media (min-width: 320px) and (max-width: 767px) {
    // margin: 27px 72px auto 17px;
    margin: 27px 17px auto 17px;
    padding-top: 76px;
    padding-left: 17px;
  }
}

.form-title {
  font-weight: 700;
  line-height: 140%;
  margin-right: 40px;
  margin-top: 40px;

  @media (min-width: 320px) and (max-width: 767px) {
    font-weight: 700;
    margin-top: -51px;
    margin-right: 10px;
    // position: absolute;
  }
}

.form-lable {
  font-weight: 500;
  font-size: 20px;
  line-height: 140%;
  margin-top: 32px;
  margin-bottom: 16px;
}

.form-table {
  min-width: 500px;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px;
  overflow: hidden;

  &:first-of-type {
    td:nth-child(2) {
      input {
        width: 24px;
      }
    }
  }

  th {
    background-color: #f9fafc;
    color: #78858b;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;

    &:first-of-type {
      border-radius: 8px 0 0 0;
    }

    &:last-of-type {
      border-radius: 0 8px 0 0;
      width: 68px;
    }
  }

  td > input,
  td > select {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #39393a;
    border: none;
    appearance: none;
    width: 100%;

    &:focus {
      border: none;
      outline: none;
    }
  }

  td > button {
    border: none;
    background-color: #fff;
    padding: 0;
  }

  tbody tr:last-of-type {
    td {
      border-bottom: 1px solid #dcdcdc;

      button {
        background-color: #fff;
        border: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #199b3e;

        img {
          margin-right: 8px;
          margin-top: -2px;
        }
      }
    }

    td:first-of-type {
      border-bottom-left-radius: 8px;
    }

    td:last-of-type {
      border-bottom-right-radius: 8px;
    }
  }

  th,
  td {
    border-top: 1px solid #dcdcdc;
    min-width: 64px;
    height: 52px;

    &:first-of-type {
      border-left: 1px solid #dcdcdc;
      padding-left: 36px;
    }

    &:last-of-type {
      border-right: 1px solid #dcdcdc;
    }
  }
}

.password_inp {
  height: 56px;
}

.textArea {
  resize: none;
  height: 120px !important;
  border: 1px solid #a7a7a7 !important;
  padding: 16px 18px 80px 18px;

  &:focus {
    outline: 1px solid #21b84b;
    border: transparent !important;
  }

  &:hover {
    outline: 1px solid #21b84b;
    border-color: transparent !important;
  }
}

.input-classical {
  width: 100%;
  border: 1px solid #a7a7a7 !important;
  background: transparent;
  border-radius: 4px;
  padding: 16px 18px;

  &:focus {
    outline: 1px solid #21b84b;
    border: transparent !important;
  }

  &:hover {
    outline: 1px solid #21b84b;
    border-color: transparent !important;
  }

  &-lg {
    width: 100%;
  }

  &-md {
    width: 500px;
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    margin-bottom: 3%;
  }
  @media (min-width: 320px) and (max-width: 767px) {
    margin-bottom: 3%;
    // width: 305px;
  }
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 268px;
  border-right: 1px solid #dcdcdc;
  border-radius: 22px;
  @media (max-width: 768px) {
    min-height: 60px;
    border: none;
  }
}

.sidebar-header {
  margin-left: 3rem;
  z-index: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 1rem;
  }
}

.nav-menu {
  z-index: 4;
  box-sizing: border-box;
  @media (max-width: 768px) {
    background: white !important;
    width: 170px;
    min-height: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    border: 1px solid #dcdcdc;
    border-radius: 0 22px 22px 0;
    box-sizing: border-box;
  }

  &.active {
    @media (max-width: 768px) {
      transition: 350ms;
      z-index: 4;
      position: fixed;
      top: 0;
      left: 0;
      width: 170px;
      height: 100%;
    }
  }
}

.menu {
  &-sidebar {
    color: #78858b;
    list-style-type: none;
    font-size: 16px;
    @media (max-width: 768px) {
      padding-left: 0.5rem;
    }
  }
}

.burger-btn {
  display: none;

  &[name='open'] > img {
    margin-right: 1rem;
  }

  &[name='close'] > img {
    margin: 1.5rem 1rem 2rem;
  }

  @media (max-width: 768px) {
    display: contents;
    border: none;
    background: red !important;
  }
}

.item-link {
  text-decoration: none;
  list-style-type: none;
  color: green;
}
// important is set to always override applied styles
.active {
  padding-bottom: 12px !important;
  color: #199b3e !important;
  border-bottom: 1px solid #199b3e !important;
}

.blur {
  @media (max-width: 768px) {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #78858b8f;
    z-index: 2;
    transition: background-color 0.5s;
  }
}

.w-214 {
  width: 214px;
}

.w-700 {
  width: 700px;
}

.form-control-select {
  width: 500px;
  padding: 1rem 0.75rem;
  color: #a7a7a7;
}

.mr-72 {
  margin-right: 72px;
}

.profile_content {
  display: flex;
  flex-direction: column;
}

#main_photo {
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    display: none;
  }
}

#logo_photo {
  @media (min-width: 320px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    display: none;
  }
}

.block_sign {
  @media (min-width: 320px) and (max-width: 767px) {
    background-image: url('../assets/img/logo_grey.svg');
    width: 200px;
    height: 100px;
    background-repeat: no-repeat;
    position: relative;
    left: 1%;
  }
  @media (min-width: 768px) and (max-width: 990px) {
    background-image: url('../assets/img/logo.svg');
    width: 200px;
    height: 100px;
    background-repeat: no-repeat;
    position: relative;
    left: 1%;
  }
}

.common_bc {
  background-color: #fafafa;
}

table.table-rounded {
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 6px;
  border: 1px solid #dcdcdc;

  tr:last-child td {
    border: none;
  }
}

.chat {
  &-board {
    max-height: 50vh;
    overflow-y: scroll;
  }
}

.container-content {
  margin-top: 64px;
  margin-left: 24px;
  margin-right: 24px;
  min-height: calc(100vh - 64px);

  @include for-tablet {
    margin-top: 50px;
    margin-left: 24px;
    margin-right: 24px;
  }

  @include for-mobile {
    margin-top: 50px;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.ws-container {
  width: 95%;
  margin: 0 auto;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700;
}

.fw-500 {
  font-weight: 500;
}

$btn-border-radius: 8px;
$font-size: 13px;
$line-height: 16px;
$display: flex;
$justify-content: center;

.btn {
  display: $display;
  justify-content: $justify-content;
  line-height: $line-height;
  font-size: $font-size;
  border-radius: $btn-border-radius;

  &.btn_consideration {
    color: #eeba00;
    border: 1px solid #eeba00;
  }

  &.btn_reject {
    color: #e04040;
    border: 1px solid #e04040;
  }

  &.btn_search {
    color: #78858b;
    border: 1px solid #78858b;
  }
}

.border-red {
  border-color: #e04040 !important;
}

.color-red {
  color: #e04040 !important;
}

.fs-14 {
  font-size: 14px;
}

.fs-7 {
  font-size: 1.125rem !important;
}

.mt-6 {
  margin-top: 40px;
}

$btn-padding: 5px 25px;
$btn-border-radius: 8px;
.btn {
  padding: $btn-padding;
  border-radius: $btn-border-radius;

  &.btn_accepted {
    color: #40e0d0;
    border: 1px solid #40e0d0;
  }

  &.btn_in_progress {
    color: #4ca0e0;
    border: 1px solid #4ca0e0;
  }

  &.btn_finished {
    color: #78858b;
    border: 1px solid #78858b;
  }
}

.grid-container {
  display: grid;
  grid-template-areas:
    'a b b b'
    'a b b b'
    '. b b b'
    '. b b b'
    '. b b b';
  grid-template-columns: 3fr 1fr;
  grid-gap: 24px;
  margin: 24px;
}

.table-container {
  min-width: 100%;
  overflow: scroll;
  grid-area: a;
}

.aside-container {
  width: 100%;
  grid-area: b;
}

.checkbox-label {
  display: flex;
}

.input-checkbox {
  display: none;
}

.input-checkbox + .custom-checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid #a7a7a7;
  border-radius: 2px;
}

.input-checkbox:hover + .custom-checkbox {
  border-color: #40e0d0;
}

.input-checkbox:checked + .custom-checkbox {
  background: #40e0d0;
  border-color: #40e0d0;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/checkbox.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.radio-label {
  display: flex;
}

.input-radio {
  display: none;
}

.input-radio + .custom-radio {
  width: 16px;
  height: 16px;
  border: 1px solid #a7a7a7;
  border-radius: 50%;
}

.input-radio:hover + .custom-radio {
  border-color: #40e0d0;
}

.input-radio:checked + .custom-radio {
  background: #40e0d0;
  border-color: #40e0d0;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: url('../assets/img/dot.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

.mt-32 {
  margin-top: 32px;
}

.detail_Menu {
  border-bottom: 1px solid #dcdcdc;
  margin-right: 32px;
}

.menu-hover-effect {
  &:hover {
    color: #21b84b !important;
  }
}

.req-label-custom label {
  color: #a7a7a7;
}

.modal-dialog-centered {
  justify-content: center;
}

.btn-green_default {
  font-family: Roboto, sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 22px !important;
  background: #199b3e !important;
}

.btn-green_default:hover {
  background: #21b84b !important;
}

.link {
  &__return-back {
    color: #78858b;
    font-size: 16px;
    font-weight: 700;
    &:hover {
      color: #bababa;
    }
  }
}

input::-webkit-outer-spin-button,  /* For deleting arrows from number text fields */
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.header {
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(120, 133, 139, 0.3);
  min-height: 65px;
  overflow: auto;
  margin-right: 1.5rem;

  &::-webkit-scrollbar {
    width: 10px;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: green;
    border-radius: 10px;
    border: 3px solid red;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    display: flex;

    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    align-items: center;

    li {
      a {
        display: flex;
        align-items: center;
        font-weight: 500;
        padding: 27px 0 12px 0;

        text-decoration: none;
        color: #78858b;
        margin: 0 24px 0 0;
        border-bottom: 1px solid rgba(64, 224, 208, 0);
        transition: color 0.3s ease, border-bottom 0.3s ease;

        svg {
          path {
            fill: #78858b;
            transition: fill 0.4s ease;
          }
        }
      }

      :global a.active {
        color: var(--text-default-color);
        background: transparent;
        border-radius: 0;
        border-bottom: 1px solid var(--text-default-color);

        svg path {
          fill: var(--text-default-color);
        }
      }

      &:hover {
        a {
          color: var(--text-default-color);
          border-bottom: 1px solid var(--text-default-color);

          svg path {
            fill: var(--text-default-color);
          }
        }
      }
    }
  }
}
