.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 43px 0 144px 0;
  min-height: calc(100vh - 350px);
}

.grid {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text {
  p,
  span {
    font-family: Roboto, sans-serif !important;
  }
}
