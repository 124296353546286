.projects__page {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  margin-right: 1.5rem;
  gap: 1.5rem;

  h2 {
    font-weight: 700 !important;
  }

  @media (max-width: 1470px) {
    display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr, 1fr, 1fr;
  }
}
